.App {
  text-align: center;
  margin: 3em;
}

.MuiAlert-message
{
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


pre {
  text-align: left;
}

.nutrient-name, .nutrient-info {
  display: inline-block;
}

.nutrient-name {
  font-weight: bolder;
}


@media only screen and (max-width: 1026px) {
  /* Hide on smaller screens */
  div.redundantInfo {
      display: none;
  }
  div.App {
    margin: 0;
    padding: 0;
  }
}
